<h1 class="header">Login</h1>
<form class="form">
  <div class="content">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input autoFocus matInput [(ngModel)]="email" (ngModelChange)="emailChanged($event)" name="email" />
      <mat-hint *ngIf="errors?.email">{{ errors.email }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input [type]="showPassword ? 'text' : 'password'" name="password" matInput [(ngModel)]="pass" />
      <mat-hint *ngIf="errors?.pass">{{ errors.pass }}</mat-hint>
    </mat-form-field>
    <mat-checkbox [(ngModel)]="showPassword" name="show">Show Password</mat-checkbox>
  </div>

  <button type="submit" mat-button mat-raised-button color="primary" class="login-button" (click)="this.login()">
    Login
  </button>
  <div class="login-helpers">
    <app-link (click)="this.forgotPassword()">Forgot Password</app-link>
    <app-link (click)="this.signUp()">Sign Up</app-link>
  </div>
</form>
