export const environment = {
  production: true,
  server: "https://nzleads.com",
  firebase: {
    apiKey: "AIzaSyDz9KaYJdX33noMIz5kNFR5aB_GsngKYDE",
    authDomain: "nzleads.firebaseapp.com",
    projectId: "nzleads",
    storageBucket: "nzleads.appspot.com",
    messagingSenderId: "70803016125",
    appId: "1:70803016125:web:029c867fb7f824d9c16ad4",
    measurementId: "G-HYZQLDBGQS",
    databaseURL: "https://nzleads-default-rtdb.firebaseio.com",
    rt: "https://staging-c9011-default-rtdb.firebaseio.com/"
  }
};
