import {Component, Input} from "@angular/core";
import {NzDialogService} from "src/app/utils/services/nz-dialog.service";
import {ImageDialogComponent} from "./image-dialog.component";

@Component({
  selector: "feature-card",
  templateUrl: "feature-card.component.html",
  styleUrls: ["feature-card.component.scss"]
})
export class FeatureCard {
  @Input() Heading: string = "Lorem ipsum";
  @Input() width = "500";
  @Input() Image: string;
  @Input() OriginalImage: string;
  @Input() paddingTopImage = "0";
  @Input() SubHeading: string = "";

  constructor(private nzDialogService: NzDialogService) {}

  public openOriginialImage(): void {
    this.nzDialogService.open(ImageDialogComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vw",
      data: {imageSrc: this.OriginalImage}
    });
  }
}
