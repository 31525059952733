<section class="example-container" tabindex="0" *ngIf="ready">
  <div *ngIf="reports.length === 0">No recent activity changes detected.</div>
  <div *ngFor="let report of reports">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <span class="{{ report.turnedOn ? 'greenColor' : 'redColor' }}">
            Turned: {{ report.turnedOn ? "ON" : "OFF" }}
          </span>
          <span>({{ report.timestamp | dateTimeFromIso | timeAgo }})</span>
          <span style="font-size: 12px">
            - {{ report.timestamp | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat: "M/d/yyyy, hh:mm:a" }}
            <span *ngIf="sessionService.session.role === 'superAdmin'">
              {{ report.ownerEmail }}
            </span>
          </span>
        </mat-card-title>

        <mat-card-subtitle class="sub-subtitle">
          <div>
            <div *ngFor="let source of report.sources">
              {{ (dataService.allSourcesHashedById$ | async)[source]?.name || source }}
            </div>
          </div>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    <br />
  </div>
</section>
