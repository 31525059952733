<h2 mat-dialog-title>Business: {{ data.business.name }}</h2>

<div mat-dialog-content>
  <!--  <mat-form-field floatLabel="always" *ngIf="sessionService.session.role==='superAdmin'">-->
  <!--    <mat-label>Business Owner</mat-label>-->
  <!--    <input matInput [(ngModel)]="data.business.owner">-->
  <!--  </mat-form-field>-->

  <mat-form-field floatLabel="always">
    <mat-label>Business Name</mat-label>
    <input matInput [(ngModel)]="data.business.name" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Parent Business</mat-label>
    <mat-select [(value)]="data.business.parent">
      <mat-select-trigger>
        <div class="flexRowParent">
          <mat-icon>location_city</mat-icon>&nbsp;{{ allBusinessesFlatById[data.business.parent]?.name }}
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let business of allBusinessesFlat" value="{{ business._id }}">
        <div class="flexRowParent">
          <mat-icon>location_city</mat-icon>
          {{ business.name }}
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()" cdkFocusInitial>Cancel</button>
  <button mat-button mat-raised-button color="primary" [disabled]="saving" (click)="save()" cdkFocusInitial>
    Save
  </button>
</div>
