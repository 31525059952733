import {Source} from "../classes/source";
import {User} from "../classes/user";
import {UserPermissions} from "../classes/user-permissions";
import {ObjectId} from "mongodb";
import {Business} from "../classes/business";

export class AccountPermissions {
  static FindUserPermissionForSource(
    oldSource: Source,
    sources: Source[],
    businesses: Business[],
    user: User
  ): UserPermissions {
    if (user.superAdmin) {
      return "admin";
    }

    const oldSourcePermissions = oldSource.accounts.find(
      a => a.email === user.email
    )?.permissions[0];
    if (oldSourcePermissions) {
      return oldSourcePermissions;
    }

    return this.FindSingleFirstPermForBusinessInList(
      oldSource.businessId$,
      businesses,
      user
    );
  }

  static FindSingleFirstPermForBusinessInList = (
    businessId$: ObjectId,
    allowedBusinesses: Business[],
    user
  ): UserPermissions => {
    if (!businessId$) {
      return "admin";
    }
    const biz = allowedBusinesses.find(
      b => b._id.toString() === businessId$.toString()
    );
    if (biz) {
      const perm = biz.accounts.find(a => a.email === user.email)
        ?.permissions[0];
      return (
        perm ||
        this.FindSingleFirstPermForBusinessInList(
          biz.parent,
          allowedBusinesses,
          user
        )
      );
    }
    return "none";
  };
}
