import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {YelpConfig} from "../../../../server/src/db/classes-helpers/yelpConfig";
import {ConfigGroup} from "../../../../server/src/db/classes-helpers/config";

@Injectable({
  providedIn: "root"
})
export class YelpConfigService {
  constructor(private connectionService: ConnectionService) {}

  async get(locationId: string): Promise<ConfigGroup> {
    try {
      return await this.connectionService.post("yelpConfig/get", {
        locationId
      });
    } catch (e) {
      console.error("Error Fetching Config", e);
      throw "Error Fetching Config";
    }
  }

  async getByBusinessId(businessId: string): Promise<ConfigGroup> {
    try {
      return await this.connectionService.post("yelpConfig/getByBusinessId", {
        businessId
      });
    } catch (e) {
      console.error("Error Fetching Config", e);
      throw "Error Fetching Config";
    }
  }

  async updateByBusiness(yelpConfig: YelpConfig, businessId: string) {
    return await this.connectionService.post("yelpConfig/updateByBusiness", {
      yelpConfig,
      businessId
    });
  }

  async updateBySource(yelpConfig: YelpConfig, locationId: string) {
    return await this.connectionService.post("yelpConfig/updateBySource", {
      yelpConfig,
      locationId
    });
  }
}
