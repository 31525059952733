<div class="flexColumnParent">
  <h1 class="flexItem" mat-dialog-title>
    {{ data.isMemo ? "Memos for" : "Lead From" }}: {{ data.lead.messages[0].user_display_name }} ({{
      data.location.name
    }})
  </h1>

  <div class="content-container flexItemBiggest flexColumnParent" mat-dialog-content>
    <div class="flexItemBiggest messages-container" #scrollMe>
      <div
        *ngFor="let message of data.isMemo ? data.lead.memos : data.lead.messages; let i = index"
        class="message"
        [class.biz]="
          data.isMemo ? sessionService.session.email === message.user_display_name : message.user_type === 'BIZ'
        "
        [class.cus]="
          data.isMemo ? sessionService.session.email !== message.user_display_name : message.user_type === 'CONSUMER'
        ">
        <div class="time-stamp">
          <div class="sender-name">{{ message.user_display_name }}</div>
          <div class="time">
            {{ message.time_created | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat: "M/d/yyyy, hh:mm:a" }}
          </div>
        </div>

        <div class="message-content">
          <div
            class="yelp-message"
            [class.cus]="
              data.isMemo
                ? sessionService.session.email !== message.user_display_name
                : message.user_type === 'CONSUMER'
            ">
            <div *ngIf="message.event_type === 'TEXT'" style="white-space: pre-line">
              {{ message.event_content.text }}
            </div>

            <div *ngIf="message.event_type === 'ATTACHMENT_GROUPING'">
              <a *ngFor="let attachment of message.event_content.attachments" [href]="attachment.url" target="_blank">
                <img class="attachment-image" [src]="attachment.url" />
              </a>
            </div>
          </div>

          <div
            class="menu-button flexItem"
            *ngIf="data.isMemo && sessionService.session.email === message.user_display_name">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Message actions">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="removeMessage(i)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>

    <div class="flexItem flexRowParent send-message">
      <div class="flexItemBiggest input-container">
        <mat-form-field>
          <textarea
            matInput
            rows="4"
            [placeholder]="
              !data.isMemo && data.lead.waitingForBizSentMessageToAppear
                ? 'Verifying message received...'
                : 'Please enter a ' + (data.isMemo ? 'memo.' : 'message.')
            "
            #textToSendInput
            [disabled]="!data.isMemo && data.lead.waitingForBizSentMessageToAppear"
            [(ngModel)]="textToSend"></textarea>
        </mat-form-field>
      </div>
      <div class="flexItem send-button-container">
        <div>
          <button
            mat-icon-button
            matTooltip="Save as Quick Reply for Future Use"
            *ngIf="!this.data.isMemo"
            (click)="this.saveUserSetting()"
            [disabled]="textToSendInput.value.length < 2">
            <mat-icon>save</mat-icon>
          </button>

          <button
            mat-icon-button
            *ngIf="!this.data.isMemo"
            matTooltip="Open Quick Reply Messages"
            (click)="openUserSettingDialog()">
            <mat-icon>touch_app</mat-icon>
          </button>
        </div>

        <button
          mat-button
          mat-raised-button
          *ngIf="!this.data.isMemo"
          (click)="this.sendLeadMessage()"
          [disabled]="textToSendInput.value.length < 2">
          Send
        </button>

        <button
          mat-button
          *ngIf="this.data.isMemo"
          (click)="this.addMemo()"
          [disabled]="textToSendInput.value.length < 2">
          Add
        </button>
      </div>
    </div>
  </div>

  <div class="flexItem" mat-dialog-actions>
    <button
      mat-button
      *ngIf="!this.data.isMemo && this.data.lead.conversationLink"
      (click)="openInYelp()"
      cdkFocusInitial>
      Show in Yelp
    </button>

    <button mat-button *ngIf="sessionService.session.role === 'superAdmin'" (click)="fetchNewMessages()">
      Fetch New Messages
    </button>

    <button mat-button (click)="dialogRef.close()" cdkFocusInitial>close</button>
  </div>
</div>
