import {Component} from "@angular/core";
import {cloneDeep} from "lodash";
import {ConnectionService} from "../../Services/connection.service";
import {SessionService} from "../../Services/session.service";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-location",
  templateUrl: "./superAdmin.component.html",
  styleUrls: ["./superAdmin.component.scss"]
})
export class SuperAdminComponent {
  public isLoading = false;

  public filledTimezones = 0;

  minutes = 60;
  whatsappWebhook = {
    event_type: "message_received",
    instanceId: "54510",
    id: "",
    referenceId: "",
    data: {
      id: "false_120363159687037557@g.us_B087982EED0470DD07_972547557424@c.us",
      from: "120363159687037557@g.us",
      to: "12132198493@c.us",
      author: "972547557424@c.us",
      pushname: "Mike",
      ack: "",
      type: "chat",
      body: "%body%",
      media: "",
      fromMe: false,
      self: false,
      isForwarded: false,
      isMentioned: false,
      quotedMsg: {},
      mentionedIds: [],
      time: 1690644253
    }
  };

  whatsappMessage = "";

  constructor(
    public sessionService: SessionService,
    private connectionService: ConnectionService
  ) {}

  sendWhatsappMessage(whatsappMessage: any) {
    const webhook = cloneDeep(this.whatsappWebhook);
    webhook.data.body = webhook.data.body.replaceAll("%body%", whatsappMessage);

    this.connectionService.post("test", webhook);
  }

  redoLeads() {
    this.connectionService.post("superAdmin/redoLeads", {
      minutes: this.minutes
    });
  }

  syncBilling() {
    this.connectionService.post("superAdmin/syncBilling", {});
  }

  takeSnapshot() {
    this.connectionService.post("superAdmin/takeSnapshot");
  }

  syncYelpWebhookSources() {
    this.connectionService.post("superAdmin/syncYelpWebhooks");
  }

  empty1() {
    this.connectionService.post("superAdmin/empty1");
  }

  async fillTimezone() {
    this.isLoading = true;
    const result = await this.connectionService.post("operation-config/superAdmin/fillTimezone");
    this.filledTimezones = result.updatedCount;
    this.isLoading = false;
  }

  fakeAccepted() {
    this.connectionService.post(
      "accepted?response_type=code&scope=leads+r2r_get_businesses+r2r_business_owner&state=U2FsdGVkX18J9e/wgndQNHKSoL4K1qIedgyBVqW9aDXzhRu7rXo3SQ6AwocSg0UhxdZQJbccXCXOjwIxdyf8VQ==",
      "",
      "get"
    );
  }

  followUps() {
    this.connectionService.post("handle_followups", "");
  }
}
