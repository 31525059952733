<div class="main-content">
  <mat-checkbox [checked]="!inputConfig" (click)="toggleGlobalSettings(); selectIntegration(null)">
    {{ operationConfigComponent.globalConfigText }}
  </mat-checkbox>

  <div class="setting-wrapper" [ngClass]="{'global-config-applied': isGlobalConfigApplied}">
    <div
      *ngFor="let integration of config; let last = last"
      class="integration-container"
      [ngClass]="{expanded: selectedIntegration === integration.type}">
      <mat-card class="integration" [ngClass]="{connected: integration.enabled}">
        <div class="integration-content-wrapper">
          <div class="integration-header">
            <img
              mat-card-image
              [src]="getDefaults(integration).logoUrl"
              alt="{{ getDefaults(integration).name }}"
              class="integration-image" />
            <h3>
              {{ getDefaults(integration).name }}
              <mat-icon *ngIf="integration.enabled" matTooltip="Integration enabled">check_circle</mat-icon>
            </h3>
          </div>

          <div class="integration-content" *ngIf="selectedIntegration !== integration.type">
            <p>{{ getDefaults(integration).description }}</p>

            <mat-chip-set
              *ngIf="integration.type === notificationIntegrationType.Sms"
              class="example-chip"
              cdkDropListOrientation="horizontal">
              @for (phone of integration.phone; track phone.number) {
                <mat-chip class="example-box">{{ phone.number }}</mat-chip>
              }
            </mat-chip-set>
          </div>

          <div *ngIf="selectedIntegration === integration.type">
            <app-sms-integration-config
              [integration]="integration"
              [sourceConfig]="sourceConfig"
              *ngIf="integration.type === notificationIntegrationType.Sms"></app-sms-integration-config>
            <app-whats-app-integration-config
              [sourceConfig]="sourceConfig"
              [integration]="integration"
              *ngIf="integration.type === notificationIntegrationType.WhatsApp"></app-whats-app-integration-config>
            <app-hubspot-integration-config
              [sourceConfig]="sourceConfig"
              [integration]="integration"
              *ngIf="integration.type === notificationIntegrationType.Hubspot"></app-hubspot-integration-config>
            <app-pipe-drive-integration-config
              [sourceConfig]="sourceConfig"
              [integration]="integration"
              *ngIf="integration.type === notificationIntegrationType.PipeDrive"></app-pipe-drive-integration-config>
          </div>
        </div>

        <div class="integration-footer" (click)="selectIntegration(integration.type)">
          <button
            mat-button
            [disabled]="isGlobalConfigApplied"
            [ngClass]="{'connected-button': integration.enabled, 'connect-button': !integration.enabled}">
            {{
              selectedIntegration === integration.type ? "Close" : integration.enabled ? "View Integration" : "Connect"
            }}
          </button>
        </div>
      </mat-card>
    </div>

    <div class="overlay" *ngIf="isGlobalConfigApplied">
      <div class="overlay-text">Global Configuration Enabled</div>
    </div>
  </div>
</div>
