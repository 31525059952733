import {Component, Inject, Optional} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@UntilDestroy()
@Component({
  selector: "confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}
}
