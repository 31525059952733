import {SocialAuthService} from "@abacritt/angularx-social-login";
import {SocialUser} from "@abacritt/angularx-social-login/entities/social-user";
import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {SessionService} from "./session.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private sessionService: SessionService
  ) {
    connectionService.authError$.subscribe(async () => {
      await this.logout();
    });
  }

  getClientIdFromGaCookie() {
    const cookieValue = document.cookie.match(/_ga=([^;]*)/);
    if (cookieValue && cookieValue[1]) {
      const parts = cookieValue[1].split(".");
      // The last two segments of the _ga cookie value form the `cid`
      return parts.slice(-2)?.join(".");
    }
    return null;
  }

  async login(auth) {
    const response: any = await this.connectionService.post("login", auth);
    if (response.token) {
      this.sessionService.session = response;
    } else {
      throw response;
    }
  }

  async loginWithGoogle(socialUser: SocialUser) {
    const response: any = await this.connectionService.post("login/google-login", {
      googleAuth: {
        ...socialUser,
        origin: localStorage.getItem("origin"),
        host: location.origin,
        cid: this.getClientIdFromGaCookie()
      }
    });
    if (response.token) {
      this.sessionService.session = response;
    }
  }

  async logoutSession() {
    await this.connectionService.post("login/logout", {});
  }

  logout = async () => {
    console.log("LOGOUT");
    try {
      await this.logoutSession();
    } finally {
      this.socialAuthService.signOut(true);
      this.sessionService.session = undefined;
      this.router.navigateByUrl("", {replaceUrl: true});
    }
  };

  async signup({email, pass = undefined, code = undefined}) {
    const response: any = await this.connectionService.post("login/signup", {
      email,
      pass,
      code,
      origin: localStorage.getItem("origin"),
      host: location.origin,
      cid: this.getClientIdFromGaCookie()
    });
    if (response.state === "code") {
      this.sessionService.tempSession = {email, pass, code};
      this.router.navigateByUrl("login/confirm-code", {replaceUrl: true});
    } else if (response.session) {
      this.sessionService.session = response.session;
    } else {
      throw response;
    }
  }
}
