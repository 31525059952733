<div class="flex-2 main-content">
  <div class="main-content-container">
    <div class="header">
      <h1>Source Tokens:</h1>
      <button (click)="addYelpLocation()" mat-mini-fab color="primary">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <br />
    <div *ngFor="let yelpToken of sourceTokens">
      <mat-card [class.hasLocations]="yelpToken.locations.length > 0">
        <mat-card-header>
          <mat-card-title>
            Name: {{ yelpToken.token_info?.first_name }} {{ yelpToken.token_info?.last_name }}
          </mat-card-title>

          <mat-card-subtitle class="sub-subtitle" *ngIf="sessionService.session.role === 'superAdmin'">
            ID: {{ yelpToken._id }}
          </mat-card-subtitle>

          <mat-card-subtitle class="sub-subtitle">
            Expire:
            {{
              yelpToken.token_data?.refresh_token_expires_on
                | dateTimeFromIso
                | dateTimeToLocal
                | dateTimeToFormat: "M/d/yyyy, hh:mm:a"
            }}
            ({{ yelpToken.token_data?.refresh_token_expires_on | dateTimeFromIso | dateTimeToRelative }})
          </mat-card-subtitle>

          <mat-card-subtitle
            class="sub-subtitle"
            style="line-break: anywhere"
            *ngIf="sessionService.session.role === 'superAdmin'">
            refresh_token: {{ yelpToken.token_data?.refresh_token }}
          </mat-card-subtitle>

          <mat-card-subtitle
            class="sub-subtitle"
            style="line-break: anywhere"
            *ngIf="sessionService.session.role === 'superAdmin'">
            access_token: {{ yelpToken.token_data?.access_token }}
          </mat-card-subtitle>

          <mat-card-subtitle class="sub-subtitle">
            <span>Parent Business:</span>
            <mat-icon>location_city</mat-icon>
            <div class="icon-content">
              {{ (dataService.allBusinessesHashedById$ | async)[yelpToken.businessId$]?.name }}
            </div>
          </mat-card-subtitle>

          <mat-card-title> Sources ({{ yelpToken.locations.length }}): </mat-card-title>

          <mat-card-title>
            <table style="font-size: 13px; line-height: 13px; width: 100%">
              <tr *ngFor="let location of yelpToken.locations | nzFunc: sortSources">
                <td>
                  <button mat-button (click)="editLocation(location)">Edit</button>
                </td>
                <td [class.enabled]="location.enabled">{{ location.name || "@@@" }}</td>
                <td>
                  <span style="display: flex; align-items: center">
                    <mat-icon>location_city</mat-icon>
                    <span>{{ (dataService.allBusinessesHashedById$ | async)[location.businessId$]?.name }}</span>
                  </span>
                </td>
                <td *ngIf="sessionService.session.role === 'superAdmin'">{{ location._id }}</td>
              </tr>
            </table>
          </mat-card-title>
        </mat-card-header>
        <mat-card-actions *ngIf="yelpToken.token_data">
          <button (click)="remove(yelpToken)" *ngIf="sessionService.session.role === 'superAdmin'" mat-button>
            Remove Token
          </button>
          <button
            matTooltip="Sync Sources List with Yelp"
            (click)="refreshLocations(yelpToken)"
            mat-stroked-button
            mat-button>
            Update List
          </button>
          <button (click)="editToken(yelpToken)" mat-stroked-button mat-button>Edit</button>
          <!--          <button mat-button mat-raised-button color="primary" (click)="editMessagingSettings(yelpToken)">-->
          <!--            Messaging Settings-->
          <!--          </button>-->
        </mat-card-actions>
      </mat-card>
      <br />
    </div>
  </div>
  <mat-progress-bar *ngIf="!ready" mode="indeterminate"></mat-progress-bar>
</div>
