<div
  class="example-container flexColumnParent"
  [class.mobileQuery]="mobileQuery"
  [class.smallMobileQuery]="smallMobileQuery">
  <mat-toolbar color="primary" class="example-toolbar flexItem">
    <mat-toolbar-row>
      <button class="main-menu" *ngIf="mobileQuery" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <button class="main-menu" *ngIf="!mobileQuery" mat-icon-button (click)="sidenavToggle(sidenav)">
        <mat-icon *ngIf="sidenav.opened">chevron_left</mat-icon>
        <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
      </button>

      <dynamic-logo *ngIf="!smallMobileQuery" />
      <button
        *ngIf="(dataService.allBusinessesList$ | async) !== null"
        class="business-button"
        mat-button
        (click)="openBusinessDialog()">
        <div
          class="flexRowParent label-container"
          *ngIf="businessService.hasSubscription(dataService.currentBusiness$ | async) as status"
          [attr.title]="status.error ? 'Payment Issue' : null"
          [class.redColor]="status.error"
          [class.greenColor]="!status.error && status.working">
          <mat-icon class="flexItem">location_city</mat-icon>
          <span class="flexItemBiggest ellipsis">{{ (dataService.currentBusiness$ | async)?.name }}</span>
          <mat-icon class="chevron flexItem">chevron_left</mat-icon>
        </div>
      </button>

      <span class="example-spacer"></span>
      <button mat-button mat-flat-button [matMenuTriggerFor]="menu">
        <span *ngIf="!smallMobileQuery">Welcome </span>{{ sessionService.session.name }}
      </button>

      <mat-menu #menu="matMenu" xPosition="before">
        <!--        <button-->
        <!--          mat-menu-item-->
        <!--          *ngIf="-->
        <!--            businessService.hasSubscription(-->
        <!--              ((dataService.allBusinessesHashedById$ | async) || {})[businessService.ALL_BUSINESSES_NODE_ID]-->
        <!--            ) as status-->
        <!--          "-->
        <!--          [class.redColor]="status.error"-->
        <!--          [class.greenColor]="!status.error && status.working"-->
        <!--          (click)="openBilling()">-->
        <!--          Billing-->
        <!--        </button>-->
        <app-login-as
          *ngIf="
            sessionService.session.role === 'superAdmin' || sessionService.prevSessionSuperAdmin?.role === 'superAdmin'
          "></app-login-as>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container flexItemBiggest">
    <mat-sidenav #sidenav class="side-nav" [mode]="mobileQuery ? 'over' : 'side'">
      <div (click)="mobileQuery && sidenav.close()">
        <div style="font-size: 20px; padding: 10px" tabindex="0" *ngIf="smallMobileQuery">
          <dynamic-logo />
        </div>
        <ng-template [ngTemplateOutlet]="routing" />
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #routing>
  <button mat-menu-item routerLinkActive="active" routerLink="/app/leads">
    <mat-icon>chat</mat-icon>
    Leads
  </button>

  <button mat-menu-item routerLinkActive="active" routerLink="/app/sources">
    <mat-icon>business</mat-icon>
    Sources
  </button>

  <button mat-menu-item routerLinkActive="active" routerLink="/app/tokens">
    <mat-icon>cloud_sync</mat-icon>
    Source Tokens
  </button>

  <button mat-menu-item routerLinkActive="active" routerLink="/app/reports">
    <mat-icon>analytics</mat-icon>
    Reports
  </button>

  <button
    mat-menu-item
    routerLinkActive="active"
    routerLink="/app/graph-reports"
    *ngIf="['manovaofficial@gmail.com', 'mikila85@gmail.com'].includes(sessionService.session.email)">
    <mat-icon>analytics</mat-icon>
    Graph Reports
  </button>

  <button
    mat-menu-item
    (click)="openBilling()"
    *ngIf="
      businessService.hasSubscription(
        ((dataService.allBusinessesHashedById$ | async) || {})[businessService.ALL_BUSINESSES_NODE_ID]
      ) as status
    "
    [class.redColor]="status.error"
    [class.greenColor]="!status.error && status.working">
    <mat-icon>payment</mat-icon>
    Billing
  </button>

  <button mat-menu-item routerLinkActive="active" routerLink="/app/activity-log">
    <mat-icon>list</mat-icon>
    Activity Log
  </button>

  <!--  <button-->
  <!--    mat-menu-item-->
  <!--    routerLinkActive="active"-->
  <!--    routerLink="/app/messageSettings"-->
  <!--    *ngIf="sessionService.session.role === 'superAdmin'">-->
  <!--    <s>Global Messaging Settings</s>-->
  <!--  </button>-->

  <!--  <button-->
  <!--    mat-menu-item-->
  <!--    routerLinkActive="active"-->
  <!--    routerLink="/app/whatsapp"-->
  <!--    *ngIf="sessionService.session.role === 'superAdmin'">-->
  <!--    <s>WhatsappGroups</s>-->
  <!--  </button>-->

  <button
    mat-menu-item
    routerLinkActive="active"
    routerLink="/app/superAdmin"
    *ngIf="sessionService.session.role === 'superAdmin'">
    <mat-icon svgIcon="superAdmin"></mat-icon>
    Super Admin
  </button>

  <button mat-menu-item routerLinkActive="active" (click)="contactUsClicked()">
    <mat-icon>help</mat-icon>
    Contact Us
  </button>
</ng-template>
