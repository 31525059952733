import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StorageService {
  constructor() {}

  static getItem(key) {
    const value = localStorage.getItem(key);
    if (!value) {
      return value;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  static setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
