import {Testimonial} from "./testimonials.component";

export const USER_TESTIMONIALS: Testimonial[] = [
  {
    business: {
      name: "Nema Roofing",
      userName: "Adam",
      position: "Owner of Nema Roofing",
      imgUrl: "assets/testimonials/nema_logo.jpg",
      webSite: "https://www.nemaroofing.com"
    },
    text: "Since we started using the bot it feels like we’ve got an extra pair of hands on deck. We’re getting back to customers much faster now and the increase in sales has been noticeable, I’m really glad we made the switch.",
    rating: 5
  },
  {
    business: {
      name: "LA Expert Builders",
      userName: "Nir.B",
      position: "Marketing Manager at LA Expert Builders",
      imgUrl: "assets/testimonials/laexpert_logo.png",
      webSite: "https://laexpertbuilders.com"
    },
    text: "Handling everything manually used to take so much time, but the yelp bot changed that. The responses are fast and smooth, and the clients are happier. It’s a huge time-saver.",
    rating: 5
  },
  {
    business: {
      name: "David Star Construction",
      userName: "David B",
      position: "CEO of David Star Construction",
      imgUrl: "assets/testimonials/davidstars_logo.png",
      webSite: "https://dstarconstructions.com/"
    },
    text: "Thanks to the bot auto responding leads, more are being converted into projects. It’s like having an extra team member that never sleeps!",
    rating: 5
  },
  {
    business: {
      name: "LC HVAC",
      userName: "Leo",
      position: "Operations Manager at LC HVAC",
      imgUrl: "assets/testimonials/lcheating_logo.png",
      webSite: "https://lcheatingandair.com"
    },
    text: "We never miss any clients! yelp bot quickly handles all our leads. it’s been great for our business!",
    rating: 5
  },
  {
    business: {
      name: "Skyline Roofing",
      userName: "Raz",
      position: "Owner of Skyline Roofing",
      imgUrl: "assets/testimonials/roofinge_logo.svg",
      webSite: "https://www.skylineroofing.co.uk"
    },
    text: "We’re really impressed with NZLeads. The chatbot takes care of our yelp leads efficiently, and our customers love the fast responses. It’s been a great addition and has helped us connect better with clients.",
    rating: 5
  }
];
