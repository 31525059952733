import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Source} from "../../../../../server/src/db/classes/source";
import {SourceService} from "../../Services/source.service";
import {SessionService} from "../../Services/session.service";
import {SourcesToken} from "../../../../../server/src/db/classes/sources-token";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Business} from "../../../../../server/src/db/classes/business";
import {DataService} from "../../Services/data.service";
import {BillingService} from "../../Services/billing.service";
import {NzDialogService} from "../../utils/services/nz-dialog.service";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {cloneDeep} from "lodash";
import {BusinessService} from "../../Services/business.service";

export interface DialogData {
  source: Source;
  tokens: SourcesToken[];
  allBusinessesFlat: (Business | any)[];
  allBusinessesFlatById: {[key: string]: Business | any};
}

@UntilDestroy()
@Component({
  selector: "source-dialog",
  templateUrl: "./source-dialog.component.html",
  styleUrls: ["./source-dialog.component.scss"]
})
export class SourceDialogComponent {
  savingInProgress = false;
  originalSource: Source;
  constructor(
    private sourceService: SourceService,
    public sessionService: SessionService,
    public dataService: DataService,
    public businessService: BusinessService,
    public nzDialogService: NzDialogService,
    private billingService: BillingService,
    public dialogRef: MatDialogRef<SourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.originalSource = cloneDeep(this.data.source);
  }

  encodeURIComponent = text => {
    return encodeURIComponent(text);
  };

  async save(source: Source) {
    await this.normalizeSourceName(source);
    this.savingInProgress = true;
    try {
      await this.updateSource(source);
    } catch (e) {
      await this.handleError(e, source);
    } finally {
      this.savingInProgress = false;
    }
  }

  private async normalizeSourceName(source: Source) {
    if (source.name.trim() === "") {
      source.name = source.yelpLocationInfo.name;
    }
  }

  private async updateSource(source: Source) {
    await this.sourceService.update(source);
    this.dialogRef.close(source);
  }

  private async handleError(error: any, source: Source) {
    console.log(error);
    switch (error.errorCode) {
      case "no_billing_for_user":
        await this.billingService.promptForTrial(this.data.source);
        break;
      case "billing_not_good":
      case "self_billing_not_good":
        await this.handleBillingIssue(error, source);
        break;
      default:
        // handle other error types or rethrow
        break;
    }
  }

  private async handleBillingIssue(error: any, source: Source) {
    const selfBilling = error.errorCode === "self_billing_not_good";
    const dialogRef = this.nzDialogService.open(ConfirmDialogComponent, {
      maxWidth: 600,
      data: {
        title: "Cannot activate Source",
        mainText: error.description,
        hideConfirm: !selfBilling,
        action: "Fix issue"
      }
    });
    const confirm = await dialogRef.afterClosed().pipe(untilDestroyed(this)).toPromise();
    if (confirm) {
      await this.billingService.open(source._id);
    }
  }
}
