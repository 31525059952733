import {OnInit, Input, Directive} from "@angular/core";
import {OperationConfigComponent} from "./operation-config.component";
import {Source} from "../../../../../server/src/db/classes/source";

@Directive()
export abstract class OperationConfigBaseComponent<T> implements OnInit {
  public isGlobalConfigApplied: boolean = false;
  protected tempConfig: T | null = null;

  public config: T | null = null;
  public globalConfig: T | null = null;

  @Input() inputSource: Source;
  @Input() inputConfig: T | null = null;
  @Input() inputGlobalConfig: T;

  constructor(protected operationConfigComponent: OperationConfigComponent) {}

  ngOnInit() {
    this.initializeConfig();
  }

  /**
   * Initializes the configuration based on input values.
   */
  protected initializeConfig(): void {
    if (this.inputConfig === null || this.inputConfig === undefined) {
      this.applyGlobalConfig();
    } else {
      this.config = this.inputConfig;
    }
    this.globalConfig = this.inputGlobalConfig;
  }

  /**
   * Applies the global configuration.
   */
  protected applyGlobalConfig(): void {
    this.inputConfig = null;
    this.config = this.inputGlobalConfig;
    this.isGlobalConfigApplied = true;
  }

  /**
   * Toggles between global and original configurations.
   */
  public toggleGlobalSettings(): void {
    if (this.isGlobalConfigApplied) {
      this.restoreOriginalConfig();
    } else {
      this.backupAndApplyGlobalConfig();
    }

    this.notifyConfigChange();
  }

  /**
   * Restores the original configuration from the temporary storage.
   */
  protected restoreOriginalConfig(): void {
    if (this.tempConfig !== null) {
      this.config = this.tempConfig;
      this.tempConfig = null;
    }
    this.isGlobalConfigApplied = false;
  }

  /**
   * Backs up the current configuration and applies the global configuration.
   */
  protected backupAndApplyGlobalConfig(): void {
    this.tempConfig = this.config;
    this.config = this.globalConfig;
    this.isGlobalConfigApplied = true;
  }

  /**
   * Notifies the specific component of the configuration change.
   * To be implemented by subclasses.
   */
  protected abstract notifyConfigChange(): void;
}
