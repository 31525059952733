import {isEqual} from "lodash";
import ArrayHelpers from "./array-helpers";

export default class ObjectHelpers {
  static Compare = (o: any, n: any, deep = false) => {
    if (
      Array.isArray(n) ||
      (n && n.toString && n.toString() !== "[object Object]")
    ) {
      if (!isEqual(o, n)) {
        return deep ? [true, n] : n;
      } else {
        return deep ? [undefined] : undefined;
      }
    }

    if (n && typeof n === "object") {
      const newObj = {};
      Object.getOwnPropertyNames(n).forEach(key => {
        const [changed, change] = this.Compare(o?.[key], n[key], true);
        if (changed) {
          newObj[key] = change;
        }
      });
      if (Object.getOwnPropertyNames(newObj).length > 0) {
        return deep ? [true, newObj] : newObj;
      } else {
        return deep ? [false] : newObj;
      }
    }

    if (!isEqual(o, n)) {
      return deep ? [true, n] : n;
    } else {
      return deep ? [false] : n;
    }
  };

  static sortNodesAndChildren = nodes => {
    nodes.sort(ArrayHelpers.SortAlphabetically());
    nodes.forEach(node => {
      if (node.children) {
        this.sortNodesAndChildren(node.children);
      }
    });
  };
}
