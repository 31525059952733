import {UntilDestroy} from "@ngneat/until-destroy";
import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {LogoService} from "../../Services/logo-service";

@UntilDestroy()
@Component({
  selector: "app-accepted",
  templateUrl: "./accepted.component.html",
  styleUrls: ["./accepted.component.scss"]
})
export class AcceptedComponent {
  isSuccess = false;

  constructor(
    private router: Router,
    public logoService: LogoService
  ) {
    if (this.router.url === "/accepted_finished") {
      this.isSuccess = true;
    }
  }
}
