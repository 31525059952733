<div class="flexColumnParent" *ngIf="data">
  <h1 class="flexItem" mat-dialog-title>{{ data.name }} Access and Permissions</h1>

  <div mat-dialog-content class="flexItemBiggest content-container">
    <div class="form-container">
      <mat-card *ngFor="let account of data.accounts; let i = index">
        <mat-form-field floatLabel="always">
          <mat-label>Email</mat-label>
          <input
            matInput
            [(ngModel)]="account.email"
            [pattern]="emailRegex"
            required
            [disabled]="account.email === sessionService.session.email" />
          <mat-error>email not valid</mat-error>
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>Permissions</mat-label>
          <mat-select value="open" [(ngModel)]="account.permissions">
            <mat-option
              *ngFor="let permission of permissions | nzFunc: filterPermissions : account"
              [value]="permission">
              {{ permission }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-button (click)="removeAccount(i)">Remove</button>
      </mat-card>
    </div>
  </div>

  <div class="flexItem" mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()" cdkFocusInitial>Cancel</button>
    <button mat-button mat-raised-button color="primary" (click)="add(data.accounts)" cdkFocusInitial>Add</button>
    <button mat-button mat-raised-button color="primary" (click)="save(data)">Save</button>
  </div>
</div>
