import {Component, Input} from "@angular/core";
import {
  NotificationIntegrationType,
  SmsIntegration,
  SmsIntegrationPhone
} from "../../../../../../../server/src/db/classes/notification-integration.type";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";
import {IntegrationService} from "src/app/Services/integration.service";
import {DataService} from "src/app/Services/data.service";
import {OperationConfigType} from "../../../../../../../server/src/db/classes/operation-config.type";

@Component({
  selector: "app-sms-integration-config",
  templateUrl: "./sms-integration-config.component.html",
  styleUrl: "./sms-integration-config.component.scss"
})
export class SmsIntegrationConfigComponent {
  @Input() integration: SmsIntegration;
  @Input() sourceConfig: SourceConfigDialogData;

  public newPhone: SmsIntegrationPhone = {
    number: ""
  };
  public otpCode: number | null;
  public isVerifying: boolean = false;
  public confirmDeleteIndex: number[] = [];
  public isLodaing = false;

  constructor(
    private dataService: DataService,
    private integrationService: IntegrationService
  ) {}

  private get operationConfigId(): string {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        return this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
      }
      case OperationConfigType.Business: {
        return this.sourceConfig.businessId;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public async toggleEnable(newStatus: boolean): Promise<void> {
    const status = await this.integrationService.toggleEnable(
      this.operationConfigId,
      NotificationIntegrationType.Sms,
      this.sourceConfig.type,
      newStatus
    );

    this.integration.enabled = status;
  }

  public async startVerification() {
    this.isLodaing = true;
    try {
      const result = await this.integrationService.sendVerifyPhoneSms(this.newPhone.number, this.operationConfigId);
      if (result) {
        this.isVerifying = true;
      }
    } catch (e) {}
    this.isLodaing = false;
  }

  public async verifyCode(): Promise<void> {
    this.isLodaing = true;
    try {
      const {phone} = await this.integrationService.addVerifiedPhone(
        this.operationConfigId,
        this.newPhone,
        Number(this.otpCode),
        this.sourceConfig.type
      );

      if (phone) {
        this.integration.phone = phone;
        this.newPhone = {number: ""};
        this.otpCode = null;
        this.isVerifying = false;
      }
    } catch (e) {}

    this.isLodaing = false;
  }

  public async confirmDelete(index: number): Promise<void> {
    this.confirmDeleteIndex.push(index);
  }

  public cancelDelete(index: number): void {
    this.confirmDeleteIndex = this.confirmDeleteIndex.filter(i => i !== index);
  }

  public async deletePhoneNumber(index: number): Promise<void> {
    const result = await this.integrationService.removePhoneNumber(
      this.operationConfigId,
      this.integration.phone[index]
    );

    if (result) {
      this.integration.phone.splice(index, 1);

      if (!this.integration.phone.length) {
        this.toggleEnable(false);
      }
    } else {
      this.cancelDelete(index);
    }
  }

  public removePhoneNumber(index: number): void {
    this.integration.phone.splice(index, 1);
  }
}
