import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";

@Injectable({
  providedIn: "root"
})
export class RevokeRefreshTokenService {
  constructor(private connectionService: ConnectionService) {}

  async revoke(encodedSourceTokenId: string): Promise<{url: string | null}> {
    try {
      return await this.connectionService.post("revoke_refresh_token", {
        encodedSourceTokenId
      });
    } catch (e) {
      return {url: null};
    }
  }
}
