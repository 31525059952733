import {Component} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {Source} from "../../../../../server/src/db/classes/source";
import {
  LocationConfigDialogData,
  SourceConfigDialogComponent
} from "../../dialogs/source-config-dialog/source-config-dialog.component";
import {EntityAccountsDialogComponent} from "../../dialogs/entity-permissions-dialog/entity-accounts-dialog.component";
import {SourceDialogComponent} from "../../dialogs/source-dialog/source-dialog.component";
import {SessionService} from "../../Services/session.service";
import {SourceService} from "../../Services/source.service";
import {SourcesToken} from "../../../../../server/src/db/classes/sources-token";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {cloneDeep} from "lodash";
import {Business} from "../../../../../server/src/db/classes/business";
import {DataService} from "../../Services/data.service";
import ArrayHelpers from "../../../../../server/src/helpers/array-helpers";
import {BillingService} from "../../Services/billing.service";
import {SourceDialogService} from "../../Services/source-dialog.service";
import {OperationConfigType} from "../../../../../server/src/db/classes/operation-config.type";
import {BusinessHelperService} from "../../Services/business-helper.service";

@UntilDestroy()
@Component({
  selector: "app-location",
  templateUrl: "./sources.component.html",
  styleUrls: ["./sources.component.scss"]
})
export class SourcesComponent {
  sources: Source[] = [];
  enabledSourcesCount = 0;
  errorSourcesCount = 0;
  paymentPausedSourcesCount = 0;
  paymentWarnSourcesCount = 0;

  sourcesTokens: SourcesToken[] = [];
  allBusinessesFlatById: {[key: string]: Business | any} = {};

  ready = false;
  filterLocationsText = "";
  public filteredSources: Source[] = [];
  public maxSourcesWithoutFilter = 5;

  constructor(
    private sourceService: SourceService,
    public sessionService: SessionService,
    public sourceDialogService: SourceDialogService,
    public billingService: BillingService,
    public businessHelperService: BusinessHelperService,
    public dataService: DataService,
    private dialog: MatDialog
  ) {
    dataService.allBusinessesHashedById$.pipe(untilDestroyed(this)).subscribe(allBusinessesFlatById => {
      this.allBusinessesFlatById = allBusinessesFlatById;
    });

    dataService.currentSourcesList$.pipe(untilDestroyed(this)).subscribe(sources => {
      if (sources !== null) {
        this.refresh();
      }
    });
  }

  private refresh = async () => {
    this.sources = cloneDeep(this.dataService.currentSourcesList$.value);
    this.sourcesTokens = cloneDeep(this.dataService.allTokens$.value);
    const session = this.sessionService.session;
    const sorters = ["-enabled", "-billingPaused", "-billingWarn", "name"];
    if (session.role === "superAdmin") {
      sorters.splice(1, 0, "-error"); // add error between enabled and paused
    }
    this.sources.sort(ArrayHelpers.SortAlphabetically(sorters));
    this.enabledSourcesCount = this.sources.filter(s => s.enabled && !s.billingPaused).length;
    this.errorSourcesCount = this.sources.filter((s: Source) => s.error).length;
    this.paymentPausedSourcesCount = this.sources.filter((s: Source) => s.enabled && s.billingPaused).length;
    this.paymentWarnSourcesCount = this.sources.filter(
      (s: Source) => s.enabled && s.billingWarn && !s.billingPaused
    ).length;
    this.sources.forEach((source: Source | any) => {
      if (source.userEmail === session.email) {
        source.isOwner = true;
      }
      if (!this.sourcesTokens.some(token => token._id === source.yelpTokenId$)) {
        this.sourcesTokens.push({
          _id: source.yelpTokenId$,
          name: "TOKEN ASSIGNED BY ADMIN"
        } as any);
      }
    });
    this.filterLocationsChanged(this.sources.length <= this.maxSourcesWithoutFilter && "");
    this.ready = true;
  };

  addYelpLocation = async () => {
    this.sourceDialogService.openInviteDialog();
  };

  editLocation = (source: Source) => {
    const dialogRef = this.dialog.open(SourceDialogComponent, {
      width: "100%",
      maxWidth: "500px",

      height: "500px",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: {
        source: cloneDeep(source),
        tokens: this.sourcesTokens
      }
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async location => {
        if (location) {
          await this.dataService.updateBusinessAndSources();
          await this.refresh();
        }
      });
  };

  editLocationSettings(sourceId: string, name: string) {
    const data: LocationConfigDialogData = {
      sourceId,
      name,
      type: OperationConfigType.Location
    };

    const dialogRef = this.dialog.open(SourceConfigDialogComponent, {
      maxWidth: "800px",
      width: "100%",
      height: "80%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        this.refresh();
      });
  }

  editLocationAccounts = async (location: any) => {
    const data: any = {};
    const dialogRef = this.dialog.open(EntityAccountsDialogComponent, {
      width: "400px",
      maxHeight: "80%",
      backdropClass: "CLASSA",
      panelClass: "CLASSB",
      closeOnNavigation: false,
      data: cloneDeep(location)
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (location: Source) => {
        if (location) {
          await this.sourceService.update(location);
          await this.dataService.updateBusinessAndSources();
        }
      });
  };

  filterLocationsChanged = ($event?) => {
    this.filterLocationsText = ($event === "" ? "" : $event || this.filterLocationsText).toLowerCase();
    this.filteredSources = this.sources.filter(x => {
      return x.name?.toLowerCase().includes(this.filterLocationsText);
    });
  };

  async billing() {
    await this.billingService.open();
  }

  activateLocation(source: Source) {
    const newSource = cloneDeep(source);
    newSource.enabled = true;
    this.billingService.promptForTrial(newSource);
  }
}
