import {Component, Input} from "@angular/core";
import {WhatsAppIntegration} from "../../../../../../../server/src/db/classes/notification-integration.type";
import {DataService} from "src/app/Services/data.service";
import {IntegrationService} from "src/app/Services/integration.service";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";
import {OperationConfigType} from "../../../../../../../server/src/db/classes/operation-config.type";

@Component({
  selector: "app-whats-app-integration-config",
  templateUrl: "./whats-app-integration-config.component.html",
  styleUrl: "./whats-app-integration-config.component.scss"
})
export class WhatsAppIntegrationConfigComponent {
  @Input() integration: WhatsAppIntegration;
  @Input() sourceConfig: SourceConfigDialogData;

  constructor(
    private dataService: DataService,
    private integrationService: IntegrationService
  ) {}

  public async addWhatsapp() {
    const code = await this.getNeWhatsappCode();
    this.integration.whatsappChatsCode = code;
    this.integration.enabled = false;
  }

  private async getNeWhatsappCode(): Promise<string> {
    switch (this.sourceConfig.type) {
      case OperationConfigType.Location: {
        const locationConfigId =
          this.dataService.allSourcesHashedById$.value[this.sourceConfig.sourceId].locationConfig$;
        const {code} = await this.integrationService.generateCodeByOperationConfigId(
          locationConfigId,
          this.sourceConfig.type
        );
        return code;
      }
      case OperationConfigType.Business: {
        const {code} = await this.integrationService.generateCodeByOperationConfigId(
          this.sourceConfig.businessId,
          this.sourceConfig.type
        );
        return code;
      }
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public encodeURIComponent(text: string) {
    return encodeURIComponent(text);
  }
}
