<!--<h1 mat-dialog-title>{{data.name}}</h1>-->
<div mat-dialog-content>
  <div class="form-container">
    <h3>Parent Business</h3>

    <mat-form-field>
      <mat-label>Business name</mat-label>

      <mat-select [(value)]="data.businessId$">
        <mat-select-trigger>
          <div class="flexRowParent">
            <mat-icon>location_city </mat-icon>&nbsp;{{
              (dataService.allBusinessesHashedById$ | async)[data.businessId$?.toString()]?.name
            }}
          </div>
        </mat-select-trigger>
        <mat-option
          *ngFor="let business of dataService.allBusinessesList$ | async | nzFunc: businessService.filterRealBusiness"
          value="{{ business._id }}">
          <div class="flexRowParent">
            <mat-icon>location_city</mat-icon>
            {{ business.name }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="this.dialogRef.close()">Cancel</button>
  <button mat-button mat-raised-button color="primary" (click)="save()" cdkFocusInitial>Ok</button>
</div>
