import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs";
import {Source} from "../../../../../server/src/db/classes/source";
import {UntilDestroy} from "@ngneat/until-destroy";
import {StringConstants} from "../../../../../server/src/helpers/string-constants";

export const allSourcesGroup = {
  _id: StringConstants.AllBusinessesId,
  name: "All Sources"
};

@UntilDestroy()
@Component({
  selector: "app-location-search",
  templateUrl: "./location-search.component.html",
  styleUrls: ["./location-search.component.scss"]
})
export class LocationSearchComponent {
  @Output() changed = new EventEmitter<any>();
  @Input() allowAll = false;
  _sources = [];

  @Input() set sources(value: any[]) {
    this._sources = value;
    this._sources.forEach(l => (l.group = allSourcesGroup.name));
    this.updateLocations();
  }

  myControl = new FormControl("");
  filteredOptions;

  public _selectedSources: any;
  @Input() set selectedSources(value: any) {
    if (value) {
      this._selectedSources = value;
    } else if (this.allowAll) {
      this._selectedSources = allSourcesGroup;
    } else {
      this._selectedSources = null;
    }
  }

  ngOnInit() {
    this.updateLocations();
  }

  displayFn(location: Source): string {
    return location && location.name ? location.name : "";
  }

  souceSelected($event: MatAutocompleteSelectedEvent) {
    this._selectedSources = $event.option.value;
    this.myControl.reset();
    this.changed.next(this._selectedSources);
  }

  private updateLocations() {
    if (this.allowAll && this._sources[0] !== allSourcesGroup) {
      this._sources.unshift(allSourcesGroup);
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value: any) => {
        value = value || "";
        return this._sources.filter(location => {
          return (location.name || "").toLowerCase().includes((value?.name || value).toLowerCase());
        });
      })
    );
    if (this._selectedSources && this._selectedSources._id !== StringConstants.AllBusinessesId) {
      if (!this._sources.some(s => s._id === this._selectedSources._id)) {
        const id = this.allowAll ? allSourcesGroup : this._sources[0];
        this.souceSelected({
          option: {value: id}
        } as MatAutocompleteSelectedEvent);
      }
    }
  }
}
