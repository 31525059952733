<div class="flex-2">
  <div *ngIf="ready" class="main-content-container flexColumnParent">
    <div class="tab-container flexItemBiggest">
      <mat-tab-group>
        <mat-tab label="Welcome Message">
          <app-operation-config-welcome-message
            [inputConfig]="locationConfig.welcomeMessage"
            [inputGlobalConfig]="globalConfig.welcomeMessage"></app-operation-config-welcome-message>
        </mat-tab>

        <mat-tab label="Follow Ups">
          <app-operation-config-follow-ups
            [inputConfig]="locationConfig.followUps"
            [inputGlobalConfig]="globalConfig.followUps"></app-operation-config-follow-ups>
        </mat-tab>

        <mat-tab label="Integrations">
          <app-integrations
            [sourceConfig]="config"
            [inputConfig]="locationConfig.notificationIntegrations"
            [inputGlobalConfig]="globalConfig.notificationIntegrations"></app-integrations>
        </mat-tab>
        <mat-tab label="Phone">
          <app-operation-config-customer-phone-found
            [inputConfig]="locationConfig.sendCustomerMessageOnPhoneFound"
            [inputGlobalConfig]="globalConfig.sendCustomerMessageOnPhoneFound">
          </app-operation-config-customer-phone-found>
        </mat-tab>
        <mat-tab *ngIf="sessionService.session.role === 'superAdmin'" label="JSON">
          <div class="main-content json-editor">
            <json-editor
              #jsonEditor
              style="min-height: 100%"
              [options]="editorOptions"
              [data]="locationConfig"
              (change)="jsonChanged($event)"></json-editor>
            <button mat-button (click)="locationConfig = jsonSettings">Save</button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <mat-progress-bar *ngIf="!ready" mode="indeterminate"></mat-progress-bar>
</div>
