<div class="testimonials-container">
  <swiper-container #swiperContainer [init]="false" class="testimonials-slider" navigation="true">
    <swiper-slide *ngFor="let testimonial of testimonials">
      <mat-card class="testimonial-item flexColumnParent">
        <a [href]="testimonial.business.webSite"
          ><img
            mat-card-image
            [src]="testimonial.business.imgUrl"
            alt="{{ testimonial.business.name }}"
            class="testimonial-image flexItem"
        /></a>
        <mat-card-title class="testimonial-name flexItem">{{ testimonial.business.name }}</mat-card-title>
        <mat-card-subtitle class="testimonial-position flexItem"
          >{{ testimonial.business.userName }}, {{ testimonial.business.position }}
        </mat-card-subtitle>
        <mat-card-content class="flexItemBiggest flexColumnParent">
          <p class="testimonial-text flexItemBiggest">"{{ testimonial.text }}"</p>
          <div class="testimonial-rating flexItem">
            <ng-container *ngFor="let star of [].constructor(testimonial.rating); let i = index">
              <mat-icon fontIcon="star"></mat-icon>
            </ng-container>

            <ng-container *ngFor="let star of [].constructor(5 - testimonial.rating); let i = index">
              <mat-icon>star_border</mat-icon>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </swiper-slide>
  </swiper-container>
</div>
