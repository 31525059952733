<div class="flexColumnParent">
  <div class="flexItem">
    <mat-form-field (click)="picker.open()" class="date-picker">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker touchUi #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field floatLabel="always" class="statusFilter">
      <mat-label>Status</mat-label>
      <mat-select
        panelClass="status-dropdown"
        value="open"
        (selectionChange)="filterStatusChanged($event)"
        placeholder="All Statuses"
        [ngModel]="filterStatus"
        [multiple]="true">
        <mat-option *ngFor="let status of leadsService.statuses" [value]="status.value">
          {{ status.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="ready" class="table-container flexItemBiggest">
    <table
      mat-table
      [dataSource]="sortedSourceReports"
      matSortActive="found"
      matSortDisableClear
      matSortStart="desc"
      matSortDirection="asc"
      matSort
      (matSortChange)="announceSortChange($event)">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.field">{{ column.title }}</th>
        <td mat-cell *matCellDef="let element">{{ element[column.field] }}</td>

        <th mat-footer-cell *matFooterCellDef>{{ totalRow[column.field] }}</th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsFields; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsFields"></tr>
      <ng-container *ngIf="sessionService.session.role === 'superAdmin'">
        <tr mat-footer-row *matFooterRowDef="columnsFields; sticky: true"></tr>
      </ng-container>
    </table>
  </div>
  <mat-progress-bar *ngIf="!ready" mode="indeterminate"></mat-progress-bar>
</div>
