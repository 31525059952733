<div class="main-content">
  <mat-checkbox [checked]="!inputConfig" (click)="toggleGlobalSettings()">
    {{ operationConfigComponent.globalConfigText }}
  </mat-checkbox>
  <div class="setting-wrapper">
    <div class="action-bar">
      <button
        [class.visibilityHidden]="isGlobalConfigApplied"
        class="add-icon"
        (click)="addMessage()"
        mat-mini-fab
        color="primary">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let message of config.predefinedMessages" cdkDrag class="follow-up-message-container flexRowParent">
        <div class="card-actions flexItem">
          <button
            [class.visibilityHidden]="isGlobalConfigApplied"
            (click)="removeMessage(message)"
            class="delete-icon"
            mat-mini-fab
            color="primary">
            <mat-icon>delete</mat-icon>
          </button>
          <svg
            [class.displayNone]="isGlobalConfigApplied"
            class="drag-handle"
            cdkDragHandle
            width="24px"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path
              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <mat-card class="follow-up-message flexItemBiggest">
          <div class="time-input-container">
            <mat-form-field class="time-unit">
              <mat-label>Unit</mat-label>
              <mat-select
                [(ngModel)]="message.type"
                (ngModelChange)="updateMinutes(message)"
                [disabled]="isGlobalConfigApplied">
                <mat-option *ngFor="let type of timeUnitTypes" [value]="type">
                  {{ yelpConfigFollowUpPredefinedMessagesType[type] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="time-value">
              <mat-label>Time After Last Interaction</mat-label>
              <input
                type="number"
                matInput
                [(ngModel)]="message.displayValue"
                (ngModelChange)="updateMinutes(message)"
                [disabled]="isGlobalConfigApplied" />
            </mat-form-field>
          </div>
          <mat-form-field class="message-input">
            <mat-label>Message</mat-label>
            <textarea
              cdkTextareaAutosize
              matInput
              minlength="2"
              required
              [disabled]="isGlobalConfigApplied"
              [(ngModel)]="message.text">
            </textarea>
          </mat-form-field>
        </mat-card>
      </div>
    </div>
  </div>
</div>
