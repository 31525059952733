import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SessionService} from "../../Services/session.service";
import {UntilDestroy} from "@ngneat/until-destroy";
import {DataService} from "../../Services/data.service";
import {Regexes} from "../../../../../server/src/helpers/regexes";
import {FormGroup} from "@angular/forms";

@UntilDestroy()
@Component({
  selector: "entity-config-dialog",
  templateUrl: "./entity-accounts-dialog.component.html",
  styleUrls: ["./entity-accounts-dialog.component.scss"]
})
export class EntityAccountsDialogComponent {
  protected readonly permissions = ["admin", "manager"];

  emailRegex = Regexes.emailRegex;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public sessionService: SessionService,
    public dataService: DataService,
    public dialogRef: MatDialogRef<EntityAccountsDialogComponent>
  ) {
    data.accounts.forEach(a => (a.permissions = a.permissions[0]));
  }

  add(accounts: any[]) {
    accounts.push({email: "", permissions: "manager"});
  }

  removeAccount(index: any) {
    this.data.accounts.splice(index, 1);
  }

  async save(data: any) {
    data.accounts.forEach(a => (a.permissions = [a.permissions]));
    this.dialogRef.close(data);
  }

  public filterPermissions = (options, account) => {
    if (this.data.ownRole === "manager") {
      return [account.permissions];
    }
    return options;
  };
}
