<mat-form-field appearance="fill" *ngIf="currentIsSuperAdmin" (click)="$event.stopPropagation()">
  <input
    type="text"
    matInput
    placeholder="Select User"
    [formControl]="userControl"
    [matAutocomplete]="auto"
    (click)="onFormFieldClicked()"
    (input)="filterUsers(userControl.value)" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelected($event)" [displayWith]="displayUser">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">{{ user.email }}</mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
<ng-container *ngIf="sessionService.prevSessionSuperAdmin">
  <button mat-menu-item (click)="logoutSuperAdmin()">Back to super admin</button>
</ng-container>
