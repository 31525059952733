<div class="flexColumnParent">
  <h1 class="flexItem" mat-dialog-title>Businesses</h1>

  <div mat-dialog-content class="flexItemBiggest content-container">
    <div class="form-container">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node
          *matTreeNodeDef="let business"
          matTreeNodePadding="0"
          [style.padding-left]="business.level < 2 ? 0 : (business.level - 2) * 40 + 'px'"
          [class.selected]="business._id === (dataService.currentBusiness$ | async)?._id"
          (click)="selectBusiness(business)">
          <ng-container *ngIf="businessService.hasSubscription(business, treeControl.isExpanded(business)) as status">
            <button mat-icon-button class="visibilityHidden" *ngIf="business.level > 1">
              <mat-icon></mat-icon>
            </button>

            <button mat-icon-button matTreeNodeToggle [class.visibilityHidden]="!business.expandable">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(business) ? "expand_more" : "chevron_right" }}
              </mat-icon>
            </button>

            <button mat-icon-button disabled *ngIf="business._id !== businessService.ALL_BUSINESSES_NODE_ID">
              <mat-icon class="mat-icon-rtl-mirror">location_city</mat-icon>
            </button>

            <div class="business-name">
              <span
                [class.redColor]="status.error"
                [class.greenColor]="!status.error && status.working"
                [attr.title]="status.error ? 'Payment Issue' : null">
                {{ business.name }}
              </span>

              <span>&nbsp;(</span>
              <span class="greenColor" *ngIf="status.working" title="Working"> {{ status.working }} </span>

              <span class="redColor" *ngIf="status.error" title="Payment Issue">
                <span *ngIf="status.working">/</span>
                <span>{{ status.error }}</span>
              </span>

              <span title="Total Sources">
                <span *ngIf="status.working || status.error">/</span>
                <span>{{ status.total }}</span>
              </span>
              <span>)</span>
            </div>

            <button
              matTooltip="Permissions"
              mat-icon-button
              *ngIf="business._id !== businessService.ALL_BUSINESSES_NODE_ID"
              (click)="$event.stopPropagation(); editBusinessAccounts(business)"
              style="position: relative">
              <mat-icon>group</mat-icon>
              <span style="font-size: 12px; position: absolute"> ({{ business.accounts.length }}) </span>
            </button>

            <button
              matTooltip="Messaging Settings"
              mat-icon-button
              *ngIf="business._id !== businessService.ALL_BUSINESSES_NODE_ID"
              (click)="$event.stopPropagation(); editLocationSettings(business._id.toString(), business.name)">
              <mat-icon>forum</mat-icon>
            </button>

            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [disabled]="business.ownRole === 'manager'"
              [class.visibilityHidden]="business.ownRole === 'manager'"
              (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="addEditBusiness(business, true)"
                *ngIf="
                  business._id === businessService.ALL_BUSINESSES_NODE_ID ||
                  sessionService.session.role === 'superAdmin' ||
                  ((dataService.allBusinessesHashedById$ | async)[business._id] | nzFunc: isAdmin)
                ">
                <mat-icon>add</mat-icon>
                <span>Add</span>
              </button>

              <button
                mat-menu-item
                *ngIf="
                  business._id !== businessService.ALL_BUSINESSES_NODE_ID &&
                  (sessionService.session.role === 'superAdmin' ||
                    ((dataService.allBusinessesHashedById$ | async)[business._id] | nzFunc: isAdmin))
                "
                (click)="addEditBusiness(business)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>

              <!--            <button mat-menu-item *ngIf="business._id !== businessService.ALL_BUSINESSES_NODE_ID"-->
              <!--                    (click)="billing(business)">-->
              <!--              <mat-icon>credit_card</mat-icon>-->
              <!--              <span>Manage Subscription</span>-->
              <!--            </button>-->

              <button
                mat-menu-item
                *ngIf="
                  business._id !== businessService.ALL_BUSINESSES_NODE_ID &&
                  (sessionService.session.role === 'superAdmin' ||
                    ((dataService.allBusinessesHashedById$ | async)[business._id] | nzFunc: isAdmin)) &&
                  status.total === 0
                "
                (click)="delete(business)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>

  <div class="flexItem" mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()" cdkFocusInitial>Close</button>
  </div>
</div>
