import {Component, Input} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {SourceConfigDialogData} from "src/app/dialogs/source-config-dialog/source-config-dialog.component";
import {OperationConfigType} from "../../../../../server/src/db/classes/operation-config.type";
import {ConfigGroup} from "../../../../../server/src/db/classes-helpers/config";
import {YelpConfigService} from "src/app/Services/yelp-config.service";
import {SessionService} from "src/app/Services/session.service";
import {JsonEditorOptions} from "ang-jsoneditor";
import {
  YelpConfig,
  YelpConfigFollowUp,
  YelpConfigNotifyCustomer,
  YelpConfigWelcomeMessage
} from "../../../../../server/src/db/classes-helpers/yelpConfig";
import {NotificationIntegrations} from "../../../../../server/src/db/classes/notification-integration.type";
import {DataService} from "../../Services/data.service";

@UntilDestroy()
@Component({
  selector: "app-operation-config",
  templateUrl: "./operation-config.component.html",
  styleUrl: "./operation-config.component.scss"
})
export class OperationConfigComponent {
  @Input() config: SourceConfigDialogData;
  @Input() saveButton = true;

  public locationConfig: YelpConfig;
  public globalConfig: YelpConfig;
  public jsonSettings: YelpConfig;

  public editorOptions: JsonEditorOptions;
  public ready = false;

  constructor(
    private yelpConfigService: YelpConfigService,
    private dataService: DataService,
    public sessionService: SessionService
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = "code";
  }

  ngOnInit() {
    this.init();
  }

  get globalConfigText() {
    if (this.config.type === OperationConfigType.Location) {
      //Source
      return "Use Default Settings from Business";
    } else {
      //Business
      if (this.dataService.allBusinessesHashedById$.value[this.config.businessId]?.parent) {
        return "Use Default Settings from Parent Business";
      }
      return "Use Default Global Settings";
    }
  }

  private async init(): Promise<void> {
    const {system, location} = await this.getSettings();
    this.locationConfig = location;
    this.globalConfig = system;
    this.ready = true;
  }

  private async getSettings(): Promise<ConfigGroup> {
    switch (this.config.type) {
      case OperationConfigType.Location:
        return await this.yelpConfigService.get(this.config.sourceId);
      case OperationConfigType.Business:
        return await this.yelpConfigService.getByBusinessId(this.config.businessId);
      default:
        throw new Error("Unsupported operation type");
    }
  }

  public jsonChanged = ($event: YelpConfig) => {
    if ($event.constructor.name !== "Event") {
      this.jsonSettings = $event;
    }
  };

  public followUpsConfigChaged(config: YelpConfigFollowUp, isGlobalConfigApplied: boolean = false) {
    if (isGlobalConfigApplied) {
      delete this.locationConfig.followUps;
      return;
    }

    this.locationConfig.followUps = config;
  }

  public welcomeMessageConfigChaged(config: YelpConfigWelcomeMessage, isGlobalConfigApplied: boolean = false) {
    if (isGlobalConfigApplied) {
      delete this.locationConfig.welcomeMessage;
      return;
    }

    this.locationConfig.welcomeMessage = config;
  }

  public customerNotifyConfigChaged(config: YelpConfigNotifyCustomer, isGlobalConfigApplied: boolean = false) {
    if (isGlobalConfigApplied) {
      delete this.locationConfig.sendCustomerMessageOnPhoneFound;
      return;
    }

    this.locationConfig.sendCustomerMessageOnPhoneFound = config;
  }

  public notificationIntegrationsNotifyConfigChaged(
    config: NotificationIntegrations,
    isGlobalConfigApplied: boolean = false
  ) {
    if (isGlobalConfigApplied) {
      delete this.locationConfig.notificationIntegrations;
      return;
    }

    this.locationConfig.notificationIntegrations = config;
  }
}
