import {Component, Inject} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {DOCUMENT, ViewportScroller} from "@angular/common";
import {trialDays} from "../../../../../server/src/modules/billing/statics";
import {LogoService} from "../../Services/logo-service";

@UntilDestroy()
@Component({
  selector: "privacy",
  templateUrl: "./term-of-service.component.html",
  styleUrls: ["./term-of-service.component.scss"]
})
export class TermOfServiceComponent {
  trialDays = trialDays;

  constructor(
    private viewportScroller: ViewportScroller,
    public logoService: LogoService,
    @Inject(DOCUMENT) public document: Document
  ) {}
  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
