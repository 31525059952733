import {Injectable} from "@angular/core";
import {Business} from "../../../../server/src/db/classes/business";
import {Source} from "../../../../server/src/db/classes/source";
import {DataService} from "./data.service";

@Injectable({
  providedIn: "root"
})
export class BusinessHelperService {
  constructor(private dataService: DataService) {}

  sourceHasBilling(source: Source, business?: Business) {
    if (business && !business?.parent) {
      return false;
    }
    const parentBusiness: Business =
      this.dataService.allBusinessesHashedById$.value[business?.parent?.toString() || source.businessId$.toString()];
    if (!parentBusiness) {
      return false;
    }
    if (parentBusiness.billingId$) {
      return true;
    }
    return this.sourceHasBilling(source, parentBusiness);
  }
}
