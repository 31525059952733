<div class="flexColumnParent">
  <div class="main-actions flexItem flexRowParent">
    <mat-form-field (click)="picker.open()" class="flexItem date-picker">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker touchUi #picker></mat-date-range-picker>
    </mat-form-field>

    <app-location-search
      class="flexItem"
      [selectedSources]="selectedBusiness"
      (changed)="locationSelectionChanged($event)"
      [sources]="locations">
    </app-location-search>
  </div>

  <div class="table-container flexItemBiggest">
    <table
      mat-table
      [dataSource]="sortedLocationReports"
      multiTemplateDataRows
      matSortActive="found"
      matSortDisableClear
      matSortStart="desc"
      matSortDirection="asc"
      matSort
      (matSortChange)="announceSortChange($event)">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.field">{{ column.title }}</th>
        <td mat-cell *matCellDef="let element" (click)="expandedElement = expandedElement === element ? null : element">
          {{ element[column.field] }}
        </td>
        <!--      <td mat-footer-cell *matFooterCellDef> Total </td>-->
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <canvas
              baseChart
              style="width: 100%; height: 600px"
              [data]="barChartData"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [type]="'scatter'">
            </canvas>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsFields; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsFields"
        [class.example-expanded-row]="expandedElement === row"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      <!--    <tr mat-footer-row *matFooterRowDef="columnsFields; sticky: true"></tr>-->
    </table>
  </div>
</div>
